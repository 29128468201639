import React from 'react'

const SeoContent = () => {
  return (
    <div className='bg-gray-50 rounded-3xl py-6 md:py-12'>
    {/* Printer Setup and Driver Installation*/}
    <div className="mx-auto max-w-screen-lg mt-4 flex flex-col px-8 py-4">
        <h2 className="text-2xl font-semibold mt-2">
        Printer Setup and Driver Installation
        </h2>
        <p className="text-base pt-2">
        Setting up a new printer can be intimidating, but our printer driver installation guide simplifies the process:
        </p>
        <h3 className="text-xl font-medium mt-2">
        Step-by-Step Process
        </h3>
        <p className="text-base">
        1.	Unbox and Prepare: Carefully unpack the printer and make sure all the components of the printer are intact.
        </p>
        <p className="text-base">
        2.	Power Connection: Plug in the power cable/plug and turn on the printer.
        </p>
        <p className="text-base">
        3.	Paper Loading: Load the paper into the tray, adjusting the guides for proper alignment.
        </p>
        <p className="text-base">
        4.	Connect to Device: Use a USB cable or connect to your Wi-Fi network for a wireless printer setup.
        </p>
        <p className="text-base">
        5.	Driver Installation: Download drivers from the manufacturer’s official website and follow the on-screen steps.
        </p>
        
        <p className="text-base pt-2">
        On our website, you will also find print from Android phone using HP Envy and many other printer-related solutions for respective problems.
        </p>
    </div>


    {/* Printer Troubleshooting Made Easy	*/}
    <div className="mx-auto max-w-screen-lg mt-2 flex flex-col px-8">
        <h2 className="text-2xl font-semibold mt-2">
        Printer Troubleshooting Made Easy	
        </h2>
        <h3 className="text-xl font-medium mt-2">
        1. Printer Offline Issues
        </h3>
        <p className="text-base pt-2">
        In case your new/old printer is not responding, follow the steps given below to resolve this issue:
        </p>
        
        <p className="text-base">
        1. Restart Devices: Power cycle both your printer and computer.
        </p>
        <p className="text-base">
        2. Check Network Settings: Confirm that your printer is connected to the correct Wi-Fi network.
        </p>
        <p className="text-base">
        3.	Reset Printer: Restore factory settings if the problem still persists.
        </p>
        <p className="text-base">
        4. Reinstall Drivers: Uninstall and reinstall the printer software to refresh settings.
        </p>
        <p className="text-base pt-2">
        These steps work well for models like the HP LaserJet Printer, Canon Pixma G3270, or Epson Printer.
        </p>
      </div>


    {/* Printer Troubleshooting Made Easy	*/}
    <div className="mx-auto max-w-screen-lg mt-2 flex flex-col px-8">
        
        <h3 className="text-xl font-medium mt-2">
            2. Fixing HP Printer Not Printing Color       
         </h3>
        <p className="text-base pt-2">
        Color printing errors can arise from clogged printheads or driver issues:
        </p>
        
        <p className="text-base">
        1. Clean the Printhead: Use the printer’s maintenance tools to clean printheads. They come with the box
        </p>
        <p className="text-base">
        2. Verify Cartridge Installation: Make sure the HP printer ink or printer cartridge is securely put in place.
        </p>
        <p className="text-base">
        3. Reset Printer: Restore factory settings if the problem still persists.
        </p>
        <p className="text-base">
        4. Update Drivers: Download and install the latest drivers from the HP website.
        </p>
        <p className="text-base pt-2">
        For detailed guidance, check out our how to fix HP printer not printing color guide on the website itself.
        </p>
      </div>

    </div>
  )
}

export default SeoContent