
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import { Helmet } from "react-helmet";

function SomePolicy() {

  return (
    <div>
      <Helmet>
      <link rel="canonical" href="https://getprinterhelp.com/privacy-policy" />
            </Helmet>
      <PrivacyPolicy />
    </div>
  );
}

export default SomePolicy;
