import React from "react";
import { Helmet } from "react-helmet";

 // website schema for getprinterhelp generals 
const SchemaMarkup = () => {
  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Get Printer Help",
    "url": "https://getprinterhelp.com",
  };
  // local business schema for getprinterhelp 
  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    "name": "Get Printer Support",
    "image": "https://getprinterhelp.com/newLogo.png",
    "@id": "",
    "url": "https://getprinterhelp.com",
    "telephone": "",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "United State",
      "addressLocality": "United State",
      "postalCode": "",
      "addressCountry": "US"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 42.258121,
      "longitude": -77.973368
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "00:00",
      "closes": "23:59"
    },
    "sameAs": "https://getprinterhelp.com"
  };

  // services schema for getprinterhelp
  const serviceSchema = {
    "@context": "https://schema.org/",
    "@type": "Service",
    "serviceType": "Printer Support",
    "provider": {
      "@type": "Organization",
      "name": "Get Printer Help"
    },
    "areaServed": {
      "@type": "Place",
      "name": "USA, Canada"
    },
    "offers": {
      "@type": "Offer",
      "priceCurrency": "USD",
      "price": "*"
    }
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(websiteSchema)}</script>
      <script type="application/ld+json">{JSON.stringify(localBusinessSchema)}</script>
      <script type="application/ld+json">{JSON.stringify(serviceSchema)}</script>
    </Helmet>
  );
};

export default SchemaMarkup;
