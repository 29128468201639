import React from 'react'
import Banner from '../components/About/Banner'
import Description from '../components/About/Description'
import { Helmet } from 'react-helmet'

function About() {

  return (
    <div>
          <Helmet>
          <link rel="canonical" href="https://getprinterhelp.com/aboutus" />
            </Helmet>
        <Banner/>
        <Description/>
    </div>
  )
}

export default About