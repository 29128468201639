import { Link } from "react-router-dom";
import { CiSettings } from "react-icons/ci";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { MdOutlineTroubleshoot } from "react-icons/md";
import OfficeJetCards from "../Cards/OfficeJetCards";
import Banner from "../../assests/knowmore_banner.webp";
import { Helmet } from "react-helmet";

function HpOfficeJetKnowMore() {

  return (
    <div>
       <Helmet>
       <link rel="canonical" href="https://getprinterhelp.com/knowmore-officejet" />
            </Helmet>
      <div className="bg-blue-600 mt-20 flex flex-col md:flex mx-auto items-center justify-center gap-10 p-8 md:flex-row md:justify-around">
        <img className="w-1/2 md:w-1/4" src={Banner} alt="" />

        <div className="flex flex-col items-center justify-center">
          <Link to={"/hp-officejet-printer-pro-setup"} target="_blank">
            <CiSettings
              size={50}
              className="hover:text-white hover:scale-110 duration-200"
            />
            <h1 className="text-xl font-bold hover:text-white">Setup</h1>
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Link to={"/how-to-install-hp-printer-driver"} target="_blank">
            <FaCloudDownloadAlt
              size={50}
              className="hover:text-white hover:scale-110 duration-200"
            />
            <h1 className="text-xl font-bold hover:text-white">
              Driver Download
            </h1>
          </Link>
        </div>
        <div className="flex flex-col items-center justify-center">
          <Link to={"/hp-printer-troubleshooting-guide"} target="_blank">
            <MdOutlineTroubleshoot
              size={50}
              className="hover:text-white hover:scale-110 duration-200"
            />
            <h1 className="text-xl font-bold hover:text-white">
              TroubleShooting
            </h1>
          </Link>
        </div>
      </div>
      <OfficeJetCards />
      <div className="max-w-screen-xl p-4 mx-auto flex flex-col mt-20 justify-center w-full h-full"></div>
    </div>
  );
}

export default HpOfficeJetKnowMore;
