import React from 'react'
import SetupImage from '../../assests/setup-image.jpg'

function Setup() {
  return (
    <section className="py-6">
    <div className="container mx-auto px-8 lg:flex gap-4">
    <div className="lg:w-1/2 ">
        <img src={SetupImage} alt="" loading="lazy" className='mx-auto' /> 
    </div>

        <div className="text-start lg:text-left lg:w-1/2">
            <h2 className="text-2xl xl:text-3xl font-bold border-b-4 border-blue-700 ">
            Learn how to take a print-out from a wireless printer.
                </h2>
            <p className="text-base mt-4">Wireless printing is very common for modern designs and workflows. Follow the steps given below to ensure smooth wireless printing experience.</p>
            <p className='text-base pt-2'>Setting Up Wi-Fi on Your Printer</p>
 
            <ol className="mt-4 text-gray-800"> 
        <li>1. Firstly, access the printer’s touchscreen and hover to the Wi-Fi setup wizard..</li> 
        <li>2. Select your respective Wifi network and input the password..</li>
        <li>3. Confirm the connection and test by printing a page.</li>
        <p>For Android users, we also offer a wireless printer setup with WiFi Direct for Android devices guide on this website.</p>
    </ol>
        </div>
        
    </div>
</section>
  )
}

export default Setup