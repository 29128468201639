import { Link } from "react-router-dom";
import { troubleshootingLinks, resoucesLinks, QuickLinks } from "../../constants/index";
const Footer = () => {
  return (
    <footer className="mt-6 border-t py-12 border-neutral-700 max-w-7xl mx-auto pt-20 px-6">
      <div className="flex text-black justify-start pb-6">
             <Link to={"/"} className="flex items-center justify-center gap-2">
              <img
                src="./newLogo.png"
                alt="Desktop Logo"
                title="getprinterhelp"
                className="w-12"
              />
            </Link>
          </div>
      <div className="grid grid-cols-2 lg:grid-cols-3 gap-4">
        <div>
          <h3 className="text-md font-semibold mb-4">Quick Links</h3>
          <ul className="space-y-2">
            {QuickLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  className="text-neutral-500 hover:text-neutral-700 hover:underline"
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-md font-semibold mb-4">Troubleshooting</h3>
          <ul className="space-y-2">
            {troubleshootingLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  target="_blank"
                  className="text-neutral-500 hover:text-neutral-700 hover:underline"
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3 className="text-md font-semibold mb-4">Resources</h3>
          <ul className="space-y-2">
            {resoucesLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.to}
                  target="_blank"
                  className="text-neutral-500 hover:text-neutral-700 hover:underline"
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;