import React from 'react'
import { Link } from 'react-router-dom'
import HpEnvy from '../../assests/modelsImages/HP Envy Printers.avif'
import HpOfficeJet from '../../assests/modelsImages/HP OfficeJet Pro Printers.webp'
import HpDeskJet from '../../assests/modelsImages/HP DeskJet Ink Printers.webp'
import HpLaserJet from '../../assests/modelsImages/HP LaserJet Printers.png'

function ModelCards() {
    const items = [
        {
            id: 1,
            title: "HP Envy Printers",
            text:'Our Technician can solve your all HP Envy Printer issue. Call Us Now to get your issue resolve today.',
            link:"/envy-models",
            src: HpEnvy,
        },
        {
            id: 2,
            title: "HP OfficeJet Pro Printers",
            text:'To find a solution for your HP OfficeJet Printer, Call Us right away to resolve all the issues facing today.',
            link:"/hp-officejet-pro-printer",
            src: HpOfficeJet,
        },
        {
            id: 3,
            title: "HP DeskJet Ink Printers",
            text:'To find a solution for your HP DeskJet Printers, Call our expert and get your issues fix today.',
            link:"/hp-deskjet-ink",
            src: HpDeskJet,
        },
        {
            id: 4,
            title: "HP LaserJet Printers",
            text:'Looking for guidance on your HP Laserjet Printers, Get your all issues fixed on a call with our experts.',
            link:"/hp-laserjet-printer",
            src: HpLaserJet,
           
        }
    ];
  return (
    <div
                className="bg-gradient-to-b from-white to-white w-full text-gray-100 md:h-full text-center md:text-left p-4"
            >
                <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
                    <div className="pb-8">
                        <p className="text-black text-2xl font-bold  flex items-center justify-center pb-4 md:text-3xl">
                        Choose Your Model for Setup, Driver Download or Troubleshooting
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-16 sm:px-5 py-2 xl:gap-8">
                        {items.map(({ id, src, title,text,link }) => (
                            <div key={id} className="mt-8 sm:mt-12 border flex flex-col items-center shadow-lg shadow-gray-800 p-4 bg-gray-200 rounded-lg hover:scale-105 duration-500">
                                <img src={src} alt="" loading='lazy' className="w-[50%] h-[80%]" />
                                <h2 className='text-lg font-bold text-black p-2 mt-2'>{title}</h2>
                                <p className="text-gray-600 duration-200 mt-2">{text}</p>
                                <Link to={link} className='mt-4'>
                                    <button className='p-3 bg-blue-600 rounded-md hover:bg-blue-800 duration-200'>Printer Model</button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
  )
}

export default ModelCards