import React from 'react'
import PrinterIssues from '../../assests/printerIssue.gif'

function PrintersIssues() {
  return (
    <section className="pt-6 md:pt-10 md:ml-4 lg:ml-20 mx-auto">
    <div className="container mx-auto px-8 lg:flex gap-10">
    <div className="text-start lg:text-left lg:w-1/2 text-base">
            <h2 className="text-2xl xl:text-3xl font-bold border-b-4 border-blue-700">Canon Printer Troubleshooting Guide</h2>
            <p className="text-base mt-4">For Canon Pixma or Canon Selphy CP1500 models, troubleshooting common errors like connectivity or printing issues is simple:</p>

            <ol className="mt-4 text-gray-800 text-base">
            <li className='text-base'>1.	Canon TR4722 WiFi Setup: Go to the Wi-Fi settings on the printer’s control panel to connect to your respective network.</li>
    <li>2.	Driver Installation: Download the required Canon TR4722 driver from the Canon website.</li>
    <li>3.	Fix Printing Errors: Clean the printhead and check the cartridge alignment for best output.</li>
</ol>
<p>Whether it’s a Canon Pixma printer or another model, our Canon printer troubleshooting guide ensures you get back to printing in no time.</p>
        </div>
        <div className="lg:w-1/2 ">
        <img  src={PrinterIssues} className='mx-auto' alt="" />
            </div>
    </div>
</section>
  )
}

export default PrintersIssues