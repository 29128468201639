export const QuickLinks = [
  { to: "https://getprinterhelp.com/", text: "Home" },
    { to: "/aboutus", text: "About" },
    { to: "/privacy-policy", text: "Privacy Policy" },
    { to: "/contactus", text: "Contact Us" },
  ];
  
  export const troubleshootingLinks = [
    { to: "https://getprinterhelp.com/envy-models", text: "HP Envy Printers" },
    { to: "https://getprinterhelp.com/hp-officejet-pro-printer", text: "HP OfficeJet Pro Printers" },
    { to: "https://getprinterhelp.com/hp-deskjet-ink", text: "HP DeskJet Ink Printers" },
    { to: "https://getprinterhelp.com/hp-laserjet-printer", text: "HP LaserJet Printers" },

  ];
  
  export const resoucesLinks = [
    { to: "https://getprinterhelp.com/new-printer-setup", text: "New Printer Setup" },
    { to: "https://getprinterhelp.com/hp-printer-troubleshooting-guide", text: "Printer Troubleshooting" },
    { to: "https://getprinterhelp.com/connect-canon-printer-to-wifi", text: "Connectivity Issue" },
    { to: "https://getprinterhelp.com/guide-to-diagnose-hp-printer-problems", text: "Diagnose Printer Problem" },

  ];