import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { motion, useScroll, useMotionValueEvent } from "framer-motion";

const Navbar = () => {
  let Links = [
    { id: 1, name: "Home", link: "/" },
    { id: 2, name: "About Us", link: "/aboutus" },
    { id: 3, name: "Contact Us", link: "/contactus" },
    { id: 4, name: "Privacy Policy", link: "/privacy-policy" },
  ];
  let [open, setOpen] = useState(false);

  // framer motion
  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const previous = scrollY.getPrevious();
    if (latest > previous && latest > 150) {
      setHidden(true);
    } else {
      setHidden(false);
    }
  });

  return (
    <motion.div
      variants={{
        visible: { y: 0 },
        hidden: {
          y: "-100%",
        },
      }}
      animate={hidden ? "hidden" : "visible"}
      transition={{ duration: 0.35, ease: "easeInOut" }}
      className="z-[500] shadow-md w-full fixed top-0 left-0 font-poppins"
    >
      <div className="md:flex items-center justify-center bg-white md:px-10 px-4">
        {/* logo section */}
        <div className="font-bold cursor-pointer py-2">
          <Link to={"/"} className="">
            {/* Show desktop logo for screens larger than or equal to medium (md) */}
            <img
              src="./newLogo.png"
              alt="Desktop Logo"
              className="w-20 hidden md:block "
            />

            {/* Show mobile logo for screens smaller than medium (md) */}
            <img
              src="./newLogo.png"
              alt="Mobile Logo"
              className="w-12 block md:hidden"
            />
          </Link>
        </div>
        {/* Menu icon */}
        <div
          onClick={() => setOpen(!open)}
          className="absolute right-8 top-6 cursor-pointer md:hidden w-7 h-7"
        >
          {open ? <XMarkIcon /> : <Bars3BottomRightIcon />}
        </div>
        {/* linke items */}
        <ul
          className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in ${
            open ? "top-12" : "top-[-490px]"
          }`}
        >
          {Links.map((link) => (
            <li
              key={link.id}
              className="md:ml-6 lg:ml-8 md:my-0 my-7 font-semibold p-2 hover:scale-105 duration-200"
            >
              <Link to={link.link} className="text-dark">
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* button */}
      </div>
    </motion.div>
  );
};

export default Navbar;
