import FeatureImage from "../../assests/feature-image.webp"

function Features() {
  return (
    <div className="max-w-screen-xl p-4 mx-auto bg-white sm:px-6 lg:px-8 my-6 mb-6 gap-4">
        <div className="lg:grid lg:grid-flow-col lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="">
            <img src={FeatureImage} className="rounded-3xl w-96 sm:w-[80vw] md:w-[60vw] lg:w-full mx-auto" alt="" />
          </div>
          <div className="md:col-start-2 md:max-w-2xl mx-auto my-4">
            <h1 className="text-xl font-semibold leading-6 text-indigo-500 uppercase">
            You Are In The Right Place
            </h1>
            <h2 className="mt-2 text-2xl font-extrabold leading-8 text-gray-900  sm:text-3xl sm:leading-9">
            We Are Your trusted source for expert printer solutions and insightful tech advice.
            </h2>
            <p className="text-base pt-2 text-gray-500">
            Welcome to your most reliable and befitting source of information on everything about a printer! Whether you're wondering about how to set up a new printer, experiencing connectivity problems, or how to fix HP printer not printing color, we've got your back. From step-by-step instructions on how to install the right printer drivers to answers on how to troubleshoot HP printer color not printing, we'll have you covered.
            </p>
          </div>
        </div>
    
    </div>
  );
}

export default Features;
