import {Link} from 'react-router-dom'
import HpOfficeJet from '../../assests/modelsImages/HP OfficeJet Pro Printers.webp'
import { Helmet } from 'react-helmet';

function HPOfficeJetPro() {

    const items = [
    {
        id: 1,
        title: "HP OfficeJet 4650",
    },
    {
        id: 2,
        title: "HP OfficeJet 100",
    },
    {
        id: 3,
        title: "HP OfficeJet 150",
    },
    {
        id: 4,
        title: "HP OfficeJet 2620",
    },
    {
        id: 5,
        title: "HP OfficeJet 3830",
    },
    {
        id: 6,
        title: "HP OfficeJet 4652",
    },
    {
        id: 7,
        title: "HP OfficeJet 4655",
    },
    {
        id: 8,
        title: "HP OfficeJet 5740",
    },
    {
        id: 9,
        title: "HP OfficeJet 5743",
    },
    {
        id: 10,
        title: "HP OfficeJet Pro 6600",
    },
    {
        id: 11,
        title: "HP OfficeJet 6812",
    },
    {
        id: 12,
        title: "HP OfficeJet 7610",
    },
    {
        id: 13,
        title: "HP OfficeJet 7612",
    },
    {
        id: 14,
        title: "HP OfficeJet 8040",
    },
    {
        id: 15,
        title: "HP OfficeJet 4630",
    },
    {
        id: 16,
        title: "HP OfficeJet 4632",
    },
    {
        id: 17,
        title: "HP OfficeJet x585dn",
    },
    {
        id: 18,
        title: "HP OfficeJet 200",
    },
    {
        id: 19,
        title: "HP OfficeJet 202",
    },
    {
        id: 20,
        title: "HP OfficeJet 202c",
    },
    {
        id: 21,
        title: "HP OfficeJet 2621",
    },
    {
        id: 22,
        title: "HP OfficeJet 2622",
    },
    {
        id: 23,
        title: "HP OfficeJet 2623",
    },
    {
        id: 24,
        title: "HP OfficeJet 2624",
    },
    {
        id: 25,
        title: "HP OfficeJet 2625",
    },
    {
        id: 26,
        title: "HP OfficeJet 2626",
    },
    {
        id: 27,
        title: "HP OfficeJet 2627",
    },
    {
        id: 28,
        title: "HP OfficeJet 2628",
    },
    {
        id: 29,
        title: "HP OfficeJet 2629",
    },
    {
        id: 30,
        title: "HP OfficeJet 3831",
    },
    {
        id: 31,
        title: "HP OfficeJet 3832",
    },
    {
        id: 32,
        title: "HP OfficeJet 3833",
    },
    {
        id: 33,
        title: "HP OfficeJet 3834",
    },
    {
        id: 34,
        title: "HP OfficeJet 3835",
    },
    {
        id: 35,
        title: "HP OfficeJet 3838",
    },
    {
        id: 36,
        title: "HP OfficeJet 3839",
    },
    {
        id: 37,
        title: "HP OfficeJet 4620",
    },
    {
        id: 38,
        title: "HP OfficeJet 4621",
    },
    {
        id: 39,
        title: "HP OfficeJet 4622",
    },
    {
        id: 40,
        title: "HP OfficeJet 4623",
    },
    {
        id: 41,
        title: "HP OfficeJet 4624",
    },
    {
        id: 42,
        title: "HP OfficeJet 4625",
    },
    {
        id: 43,
        title: "HP OfficeJet 4626",
    },
    {
        id: 44,
        title: "HP OfficeJet 4627",
    },
    {
        id: 45,
        title: "HP OfficeJet 4628",
    },
    {
        id: 46,
        title: "HP OfficeJet 4629",
    },
    {
        id: 47,
        title: "HP OfficeJet 4654",
    },
    {
        id: 48,
        title: "HP OfficeJet 4657",
    },
    {
        id: 49,
        title: "HP OfficeJet 4658",
    },
    {
        id: 50,
        title: "HP OfficeJet 5741",
    },
    {
        id: 51,
        title: "HP OfficeJet 5742",
    },
    {
        id: 52,
        title: "HP OfficeJet 5744",
    },
    {
        id: 53,
        title: "HP OfficeJet 5745",
    },
    {
        id: 54,
        title: "HP OfficeJet 5746",
    },
    {
        id: 55,
        title: "HP OfficeJet 5747",
    },
    {
        id: 56,
        title: "HP OfficeJet 5748",
    },
    {
        id: 57,
        title: "HP OfficeJet 5749",
    },
    {
        id: 58,
        title: "HP OfficeJet 6100",
    },
    {
        id: 59,
        title: "HP OfficeJet 6102",
    },
    {
        id: 60,
        title: "HP OfficeJet 6103",
    },
    {
        id: 61,
        title: "HP OfficeJet 6104",
    },
    {
        id: 62,
        title: "HP OfficeJet 6105",
    },
    {
        id: 63,
        title: "HP OfficeJet 6106",
    },
    {
        id: 64,
        title: "HP OfficeJet 6107",
    },
    {
        id: 65,
        title: "HP OfficeJet 6108",
    },
    {
        id: 66,
        title: "HP OfficeJet 6109",
    },
    {
        id: 67,
        title: "HP OfficeJet 6601",
    },
    {
        id: 68,
        title: "HP OfficeJet 6602",
    },
    {
        id: 69,
        title: "HP OfficeJet 6603",
    },
    {
        id: 70,
        title: "HP OfficeJet 6604",
    },
    {
        id: 71,
        title: "HP OfficeJet 6609",
    },
    {
        id: 72,
        title: "HP OfficeJet 6605",
    },
    {
        id: 73,
        title: "HP OfficeJet 6606",
    },
    {
        id: 74,
        title: "HP OfficeJet 6607",
    },
    {
        id: 75,
        title: "HP OfficeJet 6608",
    },
    {
        id: 76,
        title: "HP OfficeJet 6700",
    },
    {
        id: 77,
        title: "HP OfficeJet 6701",
    },
];



  return (
    <div>
         <Helmet>
         <link rel="canonical" href="https://getprinterhelp.com/hp-officejet-pro-printer" />
            </Helmet>

            <h1 className='text-black text-center p-10 bg-blue-500 mt-20 text-2xl font-bold md:text-3xl lg:text-4xl'>HP OfficeJet and OfficeJet Pro Printer Setup and Install</h1>

            <div
                name=""
                className="bg-gradient-to-b from-gray-100 to-white w-full text-white md:h-full text-center md:text-left p-4"
            >
                <div className="max-w-screen-xl p-4 mx-auto flex flex-col justify-center w-full h-full">
                    <div className="pb-8 mt-6">
                        
                        <p className="text-black text-3xl font-bold  flex items-center justify-center pb-4 md:text-4xl">
                        Choose your OfficeJet Model
                        </p>
                    </div>

                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 sm:px-5 py-2">
                        {items.map(({title,link }) => (
                            <div to={link} className="mt-8 sm:mt-12 border flex flex-col items-center shadow-lg shadow-gray-600 p-4 bg-white rounded-lg hover:scale-105 duration-500 mx-auto">
                                <img src={HpOfficeJet} alt="" className="w-[30%] h-[80%]" />
                                <h1 className='text-xl font-bold text-black mt-4 mb-4 text-center'>{title}</h1>
                                <p className="text-gray-600 text-center">Printer Installation and Setup, Troubleshoot Problem</p>

                                <Link to="/knowmore-officejet" className='mt-4'>
                                    <button className='p-3 bg-slate-800 rounded-md hover:bg-slate-600 duration-200'>Know More</button>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div> 
  )
}

export default HPOfficeJetPro;